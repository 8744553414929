h1,
h2,
h3,
h4,
h5,
h6 {
  font-family: "Poppins", "-apple-system", "BlinkMacSystemFont", "Segoe UI",
    "Helvetica", "Arial", "sans-serif", "Apple Color Emoji", "Segoe UI Emoji",
    "Segoe UI Symbol";
}

/* correct the tight line height on Muli */

body,
p {
  line-height: 1.6 !important;
}

h4 {
  font-weight: 400 !important;
}

/* Main section styling */

.main-btns {
  align-items: center;
  display: flex;
  flex-wrap: wrap;
}

#nav {
  position: absolute;
  top: 0;
  width: 100%;
  z-index: 400;
}

.carousel-container {
  margin-bottom: 60px;
}

@media (max-width: 960px) {
  .carousel-container {
    flex-direction: column-reverse !important;
  }
}

#carousel-img {
  z-index: 400;
  max-width: 500px;
  align-self: center;
  max-height: 300px;
}

#carousel-img img {
  max-width: 100%;
  max-height: 100%;
}

#signoff img {
  max-width: 100%;
  max-height: 100%;
}

.carousel-quote {
  min-width: 300px;
  max-width: 700px;
}

/* reverses the order of the carousel divs */

#row-reverse {
  flex-direction: row-reverse;
}

/* swoop positioning */

.top-swoop {
  position: absolute;
  top: -2px;
  width: 100% !important;
}

.bottom-swoop {
  position: absolute;
  bottom: -2px;
  width: 100% !important;
  z-index: 0;
}

/* Terms in Signup and Signin */

.terms-help {
  /* position: absolute;
  bottom: 60px;
  left: 72px; */
  color: rgb(182, 182, 182);
}

.terms-help a {
  font-size: 14px;
}

.terms-help img {
  display: none;
}

@media (max-width: 840px) {
  .terms-help {
    position: absolute;
    left: 50%;
    transform: translate(-50%, -50%);
    width: fit-content;
  }

  .terms-help img {
    display: flex;
  }
}

.sign-icon {
  padding: 8px;
  display: flex;
  border-radius: 40px;
  margin: 4px;
}

.sign-icon svg {
  height: 32px;
  width: 32px;
}

.sign-icon:hover {
  background: rgba(0, 0, 0, 0.2);
}

.confetti-overflow {
  position: relative;
}

/* Responsive BAT pill changes oh landing main section */

.bat-pill img {
  display: none;
  height: 24px;
}

@media (max-width: 840px) {
  .bat-pill img {
    display: flex;
    align-self: flex-start;
    margin: 0 0 18px;
  }
}

@media (min-width: 576px) {
  #zindex {
    justify-content: center;
  }
}

#zindex {
  z-index: 2;
}

.fade {
  position: absolute;
  bottom: 0;
  width: 100%;
  z-index: 0;
  opacity: 0.05;
}

.email-input {
  display: flex;
  flex-direction: column;
}

@media (max-width: 720px) {
  .notification-layer {
    width: 96%;
  }
}

.summary-spacer {
  min-height: 100px;
  height: 13vw;
}

@media (min-width: 1200px) {
  .summary-spacer {
    min-height: 200px;
  }
}

.spacer {
  display: flex;
  height: 60px;
}

/* BAT Logo Animation */
.icon {
  height: 180px;
}

.bat-svg {
  overflow: visible;
}

.circle {
  animation: pulse 1.3s alternate infinite;
  z-index: 30;
}

.circle2 {
  animation: pulse 1.7s alternate infinite;
  z-index: 30;
}

.circle-delay {
  animation: pulse 2.2s alternate infinite;
  z-index: 30;
}

.circle-delay2 {
  animation: pulse 2.6s alternate infinite;
  z-index: 30;
}

@keyframes pulse {
  0% {
    opacity: 0;
    transform: translateY(0);
    transition-timing-function: ease;
  }

  100% {
    opacity: 1;
    transform: translateY(4px);
    transition-timing-function: ease;
  }
}

a {
  text-decoration: none;
}
